<template>
  <div>
    <v-dialog max-width="500" v-model="edit">
      <v-card class="mx-auto">
        <v-card-title>
          Edit preserves
          <v-spacer></v-spacer>
          <v-btn @click="edit = false" icon>
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(preserve, index) in editPreserves" :key="preserve.id">
              <v-list-item-content>
                <v-list-item-title>
                  <BaseEditable
                    placeholder="Click to edit preserve name"
                    v-model="editPreserves[index].text"
                  />
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon color="error" @click="removePreserve(index)">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-btn text color="primary" @click="addPreserve()" class="text-capitalize">
            Add <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card width="650" color="primary-light" class="mx-auto" outlined>
      <v-card-title>
        Preserves
        <v-spacer></v-spacer>
        <v-btn v-if="editable" :disabled="!editable" color="primary" icon @click="showEdit()">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div v-if="preserves && preserves.length">
          <v-list color="primary-light" nav>
            <v-list-item-group color="primary">
              <v-list-item v-for="item in preserves" :key="item.id" @click="showEdit()">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div v-else>
          <v-alert type="info" text> No preserves found </v-alert>
          <div class="text-center">
            <v-btn v-if="editable" color="primary" class="text-capitalize" @click="showEdit()"
              >Add preserves</v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Organization from '../../services/organization';
import { randomString } from '../../utils/index';
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edit: false,
      error: '',
      editPreserves: [],
      preserves: [],
      loading: true,
      editDebounce: null
    };
  },
  created() {
    this.getData();
  },
  watch: {
    editPreserves: {
      handler() {
        this.updatePreserves();
      },
      deep: true
    }
  },
  methods: {
    updatePreserves() {
      this.preserves = JSON.parse(JSON.stringify(this.editPreserves));

      if (this.editDebounce) {
        clearTimeout(this.editDebounce);
      }

      this.editDebounce = setTimeout(() => {
        const org = new Organization();
        org.updatePreserves(this.id, this.editPreserves);
        this.editDebounce = null;

        if (this.$store.state.loggedInAs.id === this.id) {
          this.$store.commit('landPreserves', this.editPreserves);
        }
      }, 1000);
    },
    addPreserve() {
      this.editPreserves.push({ text: '', id: randomString() });
    },
    removePreserve(index) {
      this.editPreserves.splice(index, 1);
    },
    showEdit() {
      if (!this.editable) {
        return;
      }
      this.editPreserves = JSON.parse(JSON.stringify(this.preserves));
      if (!this.editPreserves.length) {
        this.addPreserve();
      }
      this.edit = true;
    },
    getData() {
      const org = new Organization();
      org
        .retrieve(this.id)
        .then(res => {
          if (res.exists) {
            this.preserves = res.data()?.preserves || [];
          }
        })
        .catch(err => {
          this.error = String(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
</style>
